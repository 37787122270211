<script>
import Switcher from "@/components/switcher";
import { HomeIcon, MailIcon,KeyIcon,ShieldIcon } from "vue-feather-icons";
import emailApi from '../apis/emailApi';
import pwdApi from '../apis/pwdApi';
import { Loading } from 'element-ui'
/**
 * Auth-re-password-three component
 */
export default {
  data() {
    return {
      data: {
        email: "",
        verificationCode: "",
        newPassword: ""
      },
      codeTime: 0,
      alert: {
        show: false,
        text: ""
      },
      email: true
    };
  },
  components: {
    Switcher,
    HomeIcon,
    MailIcon,
    KeyIcon,
    ShieldIcon
  },
  methods: {
    code() {
      if (this.data.email == "") {
        this.alert.show = true
        this.alert.text = "Your email cannot be empty"
      } else {
        emailApi.email(this.data.email, res => {
          if (res) {
            //获取按钮禁用时间
            this.codeTime = 60
            const timer = setInterval(() => {
              this.codeTime--
              if (this.codeTime == 0) {
                clearInterval(timer)
              }
            },1000)
            emailApi.code({
              email: this.data.email
            }, res2 => {
              if (res2.code != 200) {
                this.alert.show = true
                //错误返回信息
                this.alert.text = res2.msg
              }
            })
          }
        })
      }
    },
    Change() {
      // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-])[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;
      if (this.data.verificationCode == "" && this.data.email == "" && this.data.newPassword== "") {
        this.alert.show = true
        this.alert.text = "Please fill out the form"
        return
      }
      if(!passwordRegex.test(this.data.newPassword)){
        this.alert.show = true
        this.alert.text = "Password must be at least 8 characters long and contain at least one letter and one digit."
        return
      }
      let data = JSON.parse(JSON.stringify(this.data))
      data.newPassword = btoa(this.data.newPassword)
      const loading = Loading.service({
        lock: true,
        text: 'Changing password...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      pwdApi.retrieve(data, res => {
        loading.close()
        if (res.code != 200) {
          this.alert.show = true
          //错误返回信息
          this.alert.text = res.msg
        } else {
          //修改成并返回登录页面
          this.$message.success("success")
          this.$router.push("/auth-login")
        }
      })
    },
    emailCheck() {
      if (this.data.email == "") {
        return
      }
      const loading = Loading.service({
        lock: true,
        text: 'Checking email...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      emailApi.email(this.data.email, res => {
        loading.close()
        if (!res) {
          this.alert.show = true
          this.alert.text = "Email is incorrect"
        }
      })
    }
  }
};
</script>

<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <section class="bg-home bg-circle-gradiant d-flex align-items-center">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Forget Password</h4>

                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="text-muted">
                        Please enter your email address. You will receive
                        your verification code information through email.
                      </p>
                      <div class="form-group">
                        <label>Email address
                          <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input type="email" class="form-control pl-5" placeholder="Enter Your Email Address"
                            name="email" v-model="data.email" @change="emailCheck()" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Verification Code
                          <span class="text-danger">*</span></label>
                        <div class="position-relative" style="display: flex;">
                          <shield-icon class="fea icon-sm icons"></shield-icon>
                          <input type="text" class="form-control pl-5" placeholder="Enter Your Verification Code"
                            name="code" v-model="data.verificationCode" />
                          <div style="margin-left: 10px;">
                            <div class="btn btn-primary" v-if="codeTime == 0" @click="code">Send</div>
                            <div class="btn btn-primary disabled" v-else>{{codeTime}}S
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>New Password
                          <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input type="password" class="form-control pl-5" placeholder="Enter Your New Password"
                            name="password" v-model="data.newPassword" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12" style="margin-bottom: 10px;" v-if="alert.show">
                      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                    </div>
                    <div class="col-lg-12">
                      <div class="btn btn-primary btn-block" @click="Change">Change password</div>
                    </div>
                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2">Remember your password ?</small>
                        <router-link to="/auth-login" class="text-dark font-weight-bold">Sign in</router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
<!--    <Switcher />-->
  </div>
</template>
